.footerContainer {
    background-color: #0378c1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 0.5em;
    min-height: 150px;
}

.footerItems {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 800;
}

.footerText {
    font-family: "Lato";
    font-weight: 800;
    color: #FFFFFF;
    text-decoration: none;
}

@media (min-width: 0px) and (max-width: 625px){
    .footerContainer {
      flex-direction: column;
      align-items: center;
    }
  }
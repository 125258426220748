.newsletterStyle {
    cursor: pointer;
    padding-bottom: 3em;
    border-bottom: 0.15em dashed #042D62;
}

.newsletterContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.newsletterTitle {
    font-family: "Lato";
    font-size: 3.5em;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    color: #044484;
    text-align: center;
}

.navLinkContainer {
    margin-right: 1em;
    width: 30%;
    border-right: 0.15em solid #e9ecef;
}

.navLinkStyle {
    font-family: "Lato";
    font-size: 1.5em;
}

.navLinkTitle {
    font-family: "Lato";
    font-size: 2em;
    font-weight: 800;
    margin: 0.375em 0.375em;
    color: #044484;
}

.newsletterImage {
    display: flex;
    justify-content: center;
}

@media (max-width: 30em) {
    .newsletterTitle {
        font-size: 1.5em;
    }

    .navLinkTitle {
        font-size: 0.75em
    }

    .navLinkStyle {
        font-size: 0.75em;
    }
}
.container {
    display: flex;
    flex-direction: column;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.contentContainer {
    color: black;
}

.imageContainer {
    opacity: 0.75;
}

.aboutContainer {
    letter-spacing: 0.25em;
    text-transform: uppercase;
    font-size: 4em;
    text-align: left;
    font-family: 'eloquent-jf-pro';
    color: #0378c1;

}

.textContainer {
    font-size: 1.5em;
    text-align: left;
    white-space: pre-line;
}

.introText {
    font-style: italic;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    color: #0378c1;
    font-weight: 800;
}

.aboutHeader {
    font-weight: 600;
    font-size: 1em;
    font-family: 'Lato';
    padding-bottom: 0.25em;
}

.aboutText {
    font-size: 0.75em;
    padding-bottom: 1em;
    font-family: 'Lato';
}
@media (max-width: 30em) {
    .aboutContainer {
        font-size: 2.75em;
    }
}
.titleContainer {
    font-size: 4.5em;
    color: #0378c1;
    text-align: center;
    margin-top: 0.25em;
}

.descriptionContainer {
    margin-top: 5em;
}

.subTitleHeader {
    font-size: 1.5em;
    font-weight: 400;
    margin-bottom: 3em;
}

.descriptionText {
    font-size: 1.5em;
    font-weight: 400;
    font-family: 'Lato';
    padding-bottom: 1em;
    white-space: pre-wrap;
}

.socialMediaText {
    font-family: 'Lato';
    font-size: 1.5em;
    padding: 0.5em 0;
}

.descriptionTitle {
    font-family: 'Dancing Script';
    font-size: 3.5em;
    padding-bottom: 0.5em;
    color: #0378c1;
}

.trainContainer {
    display: flex;
    justify-content:center;
    align-items: center;
    margin-top: 2.5em;
}

.trainContainer *,
.trainContainer *:before,
.trainContainer *:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 100%;
}
  .container {
    position: relative;
  }
  
  .content {
    position: relative;
    height: 12em; 
    overflow: hidden;
  }
  
  .track {
    position: absolute;
    border: none;
    border-top: 5px dashed black;
    color: #fff;
    background-color: #fff;
    top:170px;
  }
  
  .train {
    position: absolute;
    width: 50px;
    height: 30px;
    border:5px solid #333;
    background-color:#0378c1;
    border-radius:0 10px 0 0;
    top:100px;
    animation: scale 1s linear infinite;
  }

  @media (min-width: 925px){
    .train {
      left: 53%;
    }
  }


  @media (min-width: 625px) and (max-width: 991px){
    .train {
      left: 60%;
    }
  }

  @media (min-width: 500px) and (max-width: 624px)  {
    .train {
      left: 65%;
    }
  }



  @media (min-width: 400px) and (max-width: 499px)  {
    .train {
      left: 70%;
    }
  }

  @media (min-width: 376px) and (max-width: 399px)  {
    .train {
      left: 75%;
    }
  }

  @media (min-width: 0px) and (max-width: 375px)  {
    .train {
      left: 80%;
    }
  }
  
  @keyframes scale {
    0% {transform: scaleY(1);}
    50% {transform: scaleY(0.99);}
    100% {transform: scaleY(1);}
  }
  
  .train:before {
    content:"";
    position: absolute;
    border:5px solid #333;
    background-color: #0378c1;
    width:35px;
    height:60px;
    left:-45px;
    top:-35px;
  }
  
  .train:after {
    position: absolute;
    content:"";
    width: 100px;
    height:5px;
    border-radius:10px;
    border: 5px solid #333;
    background-color: darkgray;
    top:30px;
    left:-50px;
  }
  
  .front {
    position: absolute;
    border: 5px solid #333;
    background-color: #aed9e0;
    box-shadow: inset 2px -5px rgba(255,255,255,0.3);
    width:20px;
    height:30px;
    left:-37.5px;
    top:-20px;
  }
  
  .front:before {
    content:"";
    position: absolute;
    background-color: #333;
    width:15px;
    height:5px;
    border-radius:10px;
    top: 25px;
    left:70px;
    box-shadow: 0px 10px #333, -50px -45px #333, -86px -45px #333, -22px -41px #333,-11px -41px #333;
  }
  
  .front:after {
    content:"";
    position: absolute;
    width:12px;
    height:20px;
    border: 5px solid #333;
    left:50px;
    top:-16px;
    background-color:#adb5bd;
  }
  
  .wheels {
    position: absolute;
    z-index:1;
  }
  
  .smallOne, .smallTwo, .smallThree, .smallFour, .smallFive, .smallSix {
    position: absolute;
    border: 5px solid #333;
    border-radius:50%;
    width: 15px;
    height:15px;
    top:40px;
    background-color: darkgray;
    box-shadow: inset 2px 2px white;
    z-index:2;
  }
  
  .smallOne {
    left: 30px;
    animation: spin .5s infinite linear;
  }
  
  .smallTwo {
    left:0;
    animation: spin .5s infinite linear;
  }
  
  .smallThree {
    left:-225px;
    animation: spin .5s infinite linear;
  }
  
  .smallFour {
    left:-190px;
    animation: spin .5s infinite linear;
  }
  
  .smallFive {
    left:-130px;
    animation: spin .5s infinite linear;
  }
  
  .smallSix {
    left:-95px;
    animation: spin .5s infinite linear;
  }
  
  .big {
    position: absolute;
    border: 5px solid #333;
    border-radius:50%;
    width:25px;
    height:25px;
    background-color: darkgray;
    box-shadow: inset 2px 2px white;
    top:30px;
    left:-40px;
    animation: spin .5s infinite linear;
  }
  
  .cord {
    position: absolute;
    width: 10px;
    height:5px;
    background-color: #333;
    top:35px;
    left:-59px;
    z-index:3;
  }
  
  .cord:before {
    content:"";
    position: absolute;
    height:5px;
    width: 70px;
    background-color: #333;
    top:15px;
    left:35px;
  }
  
  .cord:after {
    content:"";
    position: absolute;
    background-color: #333;
    border-radius:50%;
    width:15px;
    height:15px;
    top:5px;
    left:29px;
  }
  
  .coach {
    position: absolute;
    width:80px;
    height:60px;
    border:5px solid #333;
    background-color: #0378c1;
    left:-145px;
    top:-20px;
  }
  
  .coach:before {
    content:"";
    position: absolute;
    width: 10px;
    height:5px;
    background-color: #333;
    top:50px;
    left:-15px;
  }
  
  .coach:after {
    content:"";
    position: absolute;
    width:80px;
    height:60px;
    border:5px solid #333;
    background-color: #0378c1;
    top:-5px;
    left:-100px;
  }
  
  .coachTwo {
    position: absolute;
    border:5px solid #333;
    background-color: #aed9e0;
    box-shadow: inset 2px -5px rgba(255,255,255,0.3);
    width: 15px;
    height:25px;
    left:-225px;
    top:-5px;
  }
  
  .coachTwo:before, .coachTwo:after {
    content:"";
    position: absolute;
    border:5px solid #333;
    background-color: #aed9e0;
    box-shadow: inset 2px -5px rgba(255,255,255,0.3);
    width: 15px;
    height:25px;
    top:-5px;
  }
  
  .coachTwo:before {
    left:30px;
  }
  
  .coachTwo:after {
    left:90px;
  }
  
  .windows {
    position: absolute;
    border:5px solid #333;
    background-color: #aed9e0;
    box-shadow: inset 2px -5px rgba(255,255,255,0.3);
    width: 15px;
    height:25px;
    left:-95px;
    top:-5px;
    z-index:6;
  }
  
  .windows:before {
    content:"";
    position: absolute;
    background-color: #333;
    height:5px;
    width: 95px;
    border-radius:10px;
    top:-20px;
    left:-153px;
    box-shadow: 95px 0px #333;
  }
  
  .windows:after {
    content:"";
    position: absolute;
    background-color: #333;
    height:5px;
    width:40px;
    top:51px;
    left:-125px;
    box-shadow: 95px 0 #333;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  #up {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: lightgray;
    border-radius: 50%;
    opacity: 0;
    top: -30px;
    left: 25.5px;
    z-index:-1;
  }
  
  .steam {
    animation: up 2.5s ease-out infinite; 
  }
  
  .steam2 {
    animation: up 1.7s ease-out infinite 1s; 
  }
  
  .steam2:before {
    content:"";
    position: absolute;
    left:5px;
    width:15px;
    height:15px;
    background-color: lightgray;
    border-radius:50%;
    top:20px;
  }
  
  @keyframes up {
    0%{
      transform: translateY(0) translateX(0) scale(0.5);
      opacity: 1;
    }
    100%{
      transform: translateY(-110px) translateX(-80px) scale(1.5);
      opacity: 0.2;
    }
  }
  